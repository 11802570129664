import * as React from "react"

export const OrderInformation = orderState => {
  const items = orderState?.orderState?.items
  const totalPrice = items?.reduce(
    (sum, item) => sum + parseInt(item?.price),
    0
  )
  return items ? (
    <>
      <label htmlFor="Beställning">
        Beställning <strong>{totalPrice} kr</strong>
      </label>
      {items?.map(item => (
        <li>
          {item?.name}, {item?.label}, {item?.price} kr
        </li>
      ))}
    </>
  ) : null
}
