import * as React from "react"
import { useForm } from "react-hook-form"
import { GrMail } from "react-icons/gr"
import styles from "./FormPage.module.css"
import { OrderInformation } from "./OrderInformation"
import cards from "../../images/visa-mastercard.png"
import swish from "../../images/swish.png"
import { useState } from "react"
export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
export enum Order {
  "CART",
  "NORMAL",
  "COURSE",
  "MOPED",
}

const exampleOrderState = {
  items: [
    {
      name: "Handledarutbildning",
      label: "Description",
      price: "Price Integer in string",
    },
  ],
}

const prettierPrint = (state: typeof exampleOrderState) =>
  `Summa: ${state?.items?.reduce(
    (sum, item) => sum + parseInt(item?.price),
    0
  )} kr \n` + state?.items?.map(i => `${i?.name}: ${i?.label}, ${i?.price} \n`)

interface Props {
  orderState?: typeof exampleOrderState
  orderType?: Order
  car?: boolean
}

export const FormPage: React.FC<Props> = ({ orderState, orderType, car }) => {
  const { register, handleSubmit } = useForm()
  const [submitted, setSubmitted] = React.useState(false)
  const onSubmit = data => {
    console.log(data)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
      .then(() => {
        console.log(encode({ "form-name": "contact", ...data }))
        setSubmitted(true)
      })
      .catch(error => console.error(error))
  }

  const isTheoryCourse = orderType === Order.NORMAL || orderType === Order.MOPED

  const [hasBooking, setHasBooking] = useState(false)
  const orderInformation = () => {
    switch (orderType) {
      case Order.COURSE:
        return (
          <>
            <p>
              <strong> Detta gäller vid intensivkurs 2 veckor </strong>
            </p>
            <li>
              Avbokning sker senast två veckor innan kursstart så får du fullt
              betalt
            </li>
            <li>
              Avbokning 1 vecka innan kursstart så får du 50% av beloppet
              återbetalt
            </li>
            <li>Avbokning vid kursstart så blir du fullt debiterad</li>
            <li>Vid ogiltig frånvaro blir man fullt debiterad</li>
            <li>Vi drar 10% administrationsavgift vid all återbetalning</li>
            <li>
              Vid akut sjukdom så krävs läkarintyg, då bli du erbjuden plats vid
              en ny kursstart
            </li>
            <br />
          </>
        )

      case Order.NORMAL:
        return (
          <>
            <p>
              Avbokning sker senast 3 dagar innan kursen. <br />
              Om du är sjuk så ska du sjukanmäla det senast 1 timme före kursens
              start samt uppvisa ett läkarintyg för att du inte ska bli
              debiterad. <br />
              Betalning sker i förväg så fort du har fått bekräftelse på bokning
              via mejl, som allra senast 3 dagar innan kursstart
              <br />
            </p>
            <h3>Betalningsalternativ</h3>
            <p>
              - Betalning sker enklast till Swish:{" "}
              <strong>123 619 79 41</strong>
            </p>
            <p>
              - Som alternativ går det att betala även med bankgiro:{" "}
              <strong>5316-9124</strong>, ange namnet på den som skall gå på
              kursen i meddelandet
            </p>
            <br />
          </>
        )

      case Order.CART:
        return (
          <p>
            Betalning sker i förväg så fort du har fått bekräftelse på bokning
            via mejl
            <br />
            Betalning sker enklast till Swish: <strong>123 619 79 41</strong>
            <br />
          </p>
        )

      case Order.MOPED:
        return (
          <>
            <div className={styles.field}>
              <label htmlFor="MopedKontakt">
                Moped - Vårdnadshavares kontaktuppgifter och personnummer
              </label>
              <textarea
                placeholder="Vänligen ange vårdnadshavares kontaktuppgifter, namn och telefon samt personnummer"
                name="MopedKontakt"
                id="MopedKontakt"
                ref={register({ required: true, min: 4 })}
              />
            </div>
            <p>
              <input
                type="checkbox"
                required
                style={{
                  height: "24px",
                  width: "24px",
                  marginRight: "8px",
                }}
              />
              Jag som vårdnadshavare tillåter att eleven får ta AM-kort.
            </p>
            <p>
              Avbokning sker senast 3 dagar innan kursen. <br />
              Om du är sjuk så ska du sjukanmäla det senast 1 timme före kursens
              start samt uppvisa ett läkarintyg för att du inte ska bli
              debiterad. <br />
              Betalning sker i förväg så fort du har fått bekräftelse på bokning
              via mejl, som allra senast 3 dagar innan kursstart
              <br />
            </p>

            <h3>Betalningsalternativ</h3>
            <p>
              - Betalning sker enklast till Swish:{" "}
              <strong>123 619 79 41</strong>
            </p>
            <p>
              - Som alternativ går det att betala även med bankgiro:{" "}
              <strong>5316-9124</strong>, ange namnet på den som skall gå på
              kursen i meddelandet
            </p>
            <br />
          </>
        )

      default:
        return (
          <p>
            Om din förfrågan innehåller en beställning återkommer vi med en
            verifierad bekräftelse för din bokning samt betalningsalternativ.
          </p>
        )
    }
  }
  return submitted ? (
    <div style={{ height: 500, margin: "64px", padding: 32 }}>
      <h1>Tack, vi hörs!</h1>
      <h3>
        Vi återkommer till dig inom kort för att bekräfta din fråga eller
        beställning. Med önskan om gott samarbete, hälsar vi dig välkommen till
        oss på Amins Trafikskola!
      </h3>
      <p>
        <a href="https://www.facebook.com/albinstrafik/" className="fbLink">
          <img
            src={require("./../../images/FB.png")}
            alt="facebook"
            width="42"
          />
        </a>
        Gilla oss på Facebook för att få bra erbjudanden, tips om lediga
        körlektioner och annan viktig information!
      </p>
    </div>
  ) : (
    <form
      onSubmit={handleSubmit(onSubmit)}
      name="contact"
      className={styles.formPage}
      data-netlify="true"
      netlify-honeypot="bot-field"
      method="post"
    >
      <input type="hidden" name="form-name" value="contact" />
      <h1>
        Skicka en bokningsförfrågan{" "}
        <GrMail size={32} className={styles.headerIcon} />
      </h1>
      <div className={styles.field}>
        <label htmlFor="Namn">Namn</label>
        <input
          type="text"
          placeholder="Ditt fullständiga namn"
          name="Namn"
          id="Namn"
          ref={register({ required: true })}
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="Mail">Mailadress</label>
        <input
          type="email"
          placeholder="Fyll i din mailadress så vi kan nå dig"
          name="Mail"
          id="Mail"
          ref={register({ required: true })}
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="Telefon">Telefon</label>
        <input
          type="tel"
          placeholder="Ditt telefonnummer"
          name="Telefon"
          id="Telefon"
          ref={register({ required: true })}
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="Personnummer">Personnummer</label>
        <input
          type="tel"
          placeholder="För beställning behövs ditt personnummer, även de 4 sista"
          name="Personnummer"
          id="Personnummer"
          ref={register({ required: true })}
        />
        <small>Format: YYYYMMDDXXXX</small>
      </div>
      {!isTheoryCourse && (
        <>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du körkortstillstånd?{" "}
              <input
                type="checkbox"
                name="har_körkortstillstånd"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du övningskört tidigare?{" "}
              <input
                type="checkbox"
                name="har_kört_tidigare"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>{" "}
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du fullfört Risk 1:an?{" "}
              <input
                type="checkbox"
                name="risk1"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du fullfört Risk 2:an?{" "}
              <input
                type="checkbox"
                name="risk2"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
              />
            </label>
          </div>
          <div className={styles.field}>
            <label>
              Tider som funkar bäst för mig (Ctrl/Cmd + klick för att markera
              flera):{" "}
              <select name="preferred_times[]" multiple>
                {car ? (
                  <>
                    <option value="10:00">10:00</option>
                    <option value="11:30">11:30</option>
                    <option value="13:30">13:30</option>
                    <option value="15:00">15:00</option>
                    <option value="16:40">16:40</option>
                  </>
                ) : (
                  <>
                    <option value="11:00">11:00</option>
                    <option value="13:30">13:30</option>
                    <option value="15:40">15:40</option>
                  </>
                )}
              </select>
            </label>
          </div>
          <div className={styles.field}>
            <label className={styles.checkbox}>
              Har du en bokad tid för uppkörning?
              <input
                type="checkbox"
                name="has_test_booked"
                ref={register({ required: false })}
                style={{ height: "24px", width: "24px", marginRight: "8px" }}
                onChange={e => {
                  setHasBooking(e.target.checked)
                }}
              />
            </label>

            <label
              style={
                !hasBooking
                  ? {
                      display: "none",
                    }
                  : {}
              }
            >
              <input
                type="date"
                ref={register({ required: false })}
                name="has_test_booked_date"
              />
            </label>
          </div>
          <div className={styles.field}>
            <label>
              När vill du börja köra?{" "}
              <input
                type="date"
                ref={register({ required: false })}
                name="start_date"
                style={{
                  background: "white",
                  borderRadius: "2px",
                }}
              />
            </label>
          </div>
        </>
      )}
      <div className={styles.field}>
        <label htmlFor="Beskrivning">
          <br />
          Övrig info/ fråga?
        </label>
        <textarea
          name="Beskrivning"
          id="info"
          ref={register({ required: false })}
        />
      </div>
      <div
        className={styles.radios}
        style={
          !car
            ? {
                display: "none",
              }
            : {}
        }
      >
        <label>Växellåda:</label>
        <label htmlFor="manuell">Manuell</label>
        <input
          type="radio"
          id="manuell"
          name="Vaxellada"
          value="Manuell"
          required={car}
          ref={register({ required: car })}
        />
        <label htmlFor="automat">Automat</label>
        <input
          type="radio"
          id="automat"
          name="Vaxellada"
          value="Automat"
          required={car}
          ref={register({ required: car })}
        />
      </div>
      <div className={styles.field}>
        <OrderInformation orderState={orderState} />
        <textarea
          value={prettierPrint(orderState)}
          name="Info"
          ref={register({ required: true })}
          style={{ display: "none" }}
        />
      </div>
      {orderInformation()}
      <div className="grid place-items-start -mt-10">
        <div className="flex">
          <img src={cards} alt="cards" className="w-48 m-0" />
          <img src={swish} alt="swish" className="w-48 m-0" />
        </div>
      </div>
      <hr />
      <h3>Behandling av personuppgifter (GDPR)</h3>
      <p>
        <input
          type="checkbox"
          required
          style={{ height: "24px", width: "24px", marginRight: "8px" }}
        />
        Jag är införstådd med att för att boka kurser eller paket så låter jag
        Amins trafikskola hantera uppgifterna som hämtas in via formuläret, och
        godkänner den behandlingen av mina personuppgifter.
      </p>
      <br />
      <input type="submit" value="Skicka förfrågan" className={styles.submit} />
    </form>
  )
}
